// extracted by mini-css-extract-plugin
export var contactdetail = "contact-module--contactdetail--44470";
export var contactdetailaddress = "contact-module--contactdetailaddress--ab657";
export var contactdetailarea = "contact-module--contactdetailarea--0529e";
export var contactdetailemail = "contact-module--contactdetailemail--a8fe4";
export var contactdetailfax = "contact-module--contactdetailfax--47c10";
export var contactdetailhotline = "contact-module--contactdetailhotline--c6c2e";
export var contactdetaillandline = "contact-module--contactdetaillandline--b192c";
export var contactdetailmobile = "contact-module--contactdetailmobile--b7803";
export var contactdetailtitle = "contact-module--contactdetailtitle--603fb";
export var contactholder = "contact-module--contactholder--64d12";
export var contactmain = "contact-module--contactmain--e9033";
export var contactmarketing = "contact-module--contactmarketing--c8125";
export var contacttitle = "contact-module--contacttitle--ad3f9";
export var maintitle = "contact-module--maintitle--1847a";