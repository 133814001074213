import React from "react"
import PropTypes from "prop-types"
import * as styles from "./style.module.css"


const ColumnContent = ({ className, children, countword, margintype }) => {
  return (
  	<div class={styles["columncontent"]+" "+ className+ " " +styles["columncontent"+countword+margintype]}>
		{children}
  	</div>
  )

}
ColumnContent.propTypes = {
  className: PropTypes.string,
  countword: PropTypes.string,
  margintype: PropTypes.string
}

ColumnContent.defaultProps = {
  className:``,
  countword:`two`,
  margintype: `marginnormal`
}

export default ColumnContent
