import React, { Component } from "react"

import ColumnContent from "../components/columncontent"
import Layout from "../components/layout"
import * as styles from '../styles/pages/contact.module.css'

import defaultdata from "../content/contactdetails.json"
const apiurl = "https://dataset.boysencrm.com/";



class ContactPage extends Component {
	state = {
		wdhtratio: 1,
		contactdata: defaultdata
	};

	componentDidMount() {
		this.handleResize()
		window.addEventListener('resize', this.handleResize)

		fetch(apiurl+'contactdetails.json')
			.then(res => res.json())
			.then(data => this.setState({contactdata: data})
			);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize)
	}

	handleResize = () => {
		this.setState(state => ({
			wdhtratio: 	window.innerWidth/window.innerHeight
		}));
	};


	render() {
		return(
		  <Layout mobileTitle={true} activePath='contact' pageTitle="Office Details">
		  	<div className={styles.contactholder}>
				<ColumnContent className={styles.contactmain} countword={this.state.wdhtratio > 1? "two": "one"} >
					<h3 className={styles.contacttitle + " font-size-regular font-style-bold color-text-primary" }>Main Office</h3>
					<ContactDetails fontsizeclass={""} object={this.state.contactdata.main} />
				</ColumnContent>
				<ColumnContent className={styles.contactmarketing} countword={this.state.wdhtratio > 1? "two": "one"} >
					<h3 className={styles.contacttitle +" font-size-regular font-style-bold color-text-primary"}>Marketing & Technical Office</h3>
					<ContactDetails fontsizeclass={""} object={this.state.contactdata.marketing} />
				</ColumnContent>
			</div>
		  </Layout>
		);
	}
}


const ContactDetails = ({ fontsizeclass, object }) => {
	return (
		<div className={styles.contactdetailarea}>
		{	object.address &&
			<div className={(fontsizeclass==="size"? "": "text-centered") + " font-size-small "+styles.contactdetail + " " + styles.contactdetailaddress}>
				{object.address.map((data, index) => {
					return <div >{data}</div>
				})}
			</div>
		}
		{ object.hotline &&
			<ContactList
				textalignclass={fontsizeclass==="size"? "": "text-centered"}
				object={object.hotline}
				title="Hotline"
				separator={""}
				classname={styles.contactdetailhotline} />
		}
		{ object.landline &&
			<ContactList
				textalignclass={fontsizeclass==="size"? "": "text-centered"}
				object={object.landline}
				title="Telephone"
				separator={""}
				classname={styles.contactdetaillandline} />
		}
		{ object.fax &&
			<ContactList
				textalignclass={fontsizeclass==="size"? "": "text-centered"}
				object={object.fax}
				title="Fax"
				separator={""}
				classname={styles.contactdetailfax} />
		}
		{ object.mobile &&
			<ContactList
				textalignclass={fontsizeclass==="size"? "": "text-centered"}
				object={object.mobile}
				title="Mobile"
				separator={""}
				classname={styles.contactdetailmobile} />
		}
		{ object.email &&
			<ContactList
				textalignclass={fontsizeclass==="size"? "": "text-centered"}
				object={object.email}
				title=""
				separator={""}
				classname={styles.contactdetailemail+" font-style-bold"} />
		}
		</div>
	)
}

const ContactList = ({ textalignclass, title, separator, classname, object }) => {
	return (
		<div className={styles.contactdetail + " font-size-small "+textalignclass+ " " + classname}>
			{title.length>0? <div className={styles.contactdetailtitle+" font-style-bold"}>{title}</div>:""}
			{object.map((data, index) => {
				return <span>{index>0?separator:""}{data}</span>
			})}
		</div>
	)
}

export default ContactPage
